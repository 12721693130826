import React from 'react';
import './loaderModal.css';

export default function LoaderModal() {
    return (
        <div className="modal_overlay">
            <div className="modal_content" style={{ position: 'relative' }}>
                <div className="loader">
                    <div className="loader_text">Загрузка...</div>
                    <div className="loader_circle"></div>
                </div>
            </div>
        </div>
    );
}
