import React, { useEffect, useState } from 'react';
import './construktorPage.css';
import OrderPhotoTape from './OrderPhotoTape/OrderPhotoTape';
import ZoomModal from './ZoomModal/ZoomModal';
import BookCard from './BookCard/BookCard';
import { useLocation, useNavigate } from 'react-router-dom'
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import html2canvas from 'html2canvas';
import getCookie from '../../functions/getCookie';
import LoaderModal from './LoaderModal/LoaderModal';

export default function ConstruktorPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const parameters = location.state;

    const [availableImages, setAvailableImages] = useState([]);
    const [zoomedImage, setZoomedImage] = useState(null);
    const [droppedImages, setDroppedImages] = useState({});
    const [books, setBooks] = useState([]);
    const [isAutoInsertDisabled, setIsAutoInsertDisabled] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [error, setError] = useState([]);
    const [cnt, setCnt] = useState({});
    const [coverSize, setCoverSize] = useState();
    const [blockSize, setBlockSize] = useState();
    const [mode, setMode] = useState();
    const [initialImages, setIntialImages] = useState([]);
    const [cachedImages, setCachedImages] = useState({});

    const [needToDownload, setNeedToDownload] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (parameters) {
            if (parameters.parameters.status === 0) {

            }
            if (parameters.parameters.status === 1) {
                if (Object.keys(parameters.photos)[0] === '0') {
                    let photoArr = [];
                    let droppedImages = {};
                    parameters.photo = {};

                    parameters.photos.forEach((book) => {
                        book.forEach((spread) => {
                            photoArr.push(spread.currentImage)
                            droppedImages[spread.id] = spread.currentImage;
                        })
                    })

                    const photoObj = {};
                    photoArr.forEach((photo) => {
                        photoObj[photo] = '';
                    })
                    parameters.photos = photoObj;
                    setDroppedImages(droppedImages);
                    setNeedToDownload(true);
                }
            }

            setCnt({
                numberOfSpreads: parameters.parameters.cnt.numberOfSpreads,
                numberOfBooks: parameters.parameters.cnt.numberOfBooks
            })
            setCoverSize(parameters.parameters.coverSize);
            setBlockSize(parameters.parameters.blockSize);
            if ((parameters.parameters.cnt.numberOfSpreads + 1) * parameters.parameters.cnt.numberOfBooks === Object.keys(parameters.photos).length) {
                setMode('autoButton')
            } else {
                setMode('copyButtons')
            }
            setIntialImages(Object.keys(parameters.photos));
        }
    }, [parameters])

    function createBooks(cnt) {
        const books = [];
        const { numberOfSpreads, numberOfBooks } = cnt;

        for (let bookIndex = 1; bookIndex <= numberOfBooks; bookIndex++) {
            const spreads = [];

            for (let spreadIndex = 0; spreadIndex < numberOfSpreads + 1; spreadIndex++) {
                spreads.push({
                    id: `Album_${bookIndex} Page_${spreadIndex} of ${numberOfSpreads}`,
                    currentImage: droppedImages[`Album_${bookIndex} Page_${spreadIndex} of ${numberOfSpreads}`] || null,
                });
            }

            books.push(spreads);
        }

        return books;
    }

    useEffect(() => {
        if (!needToDownload) {
            setAvailableImages(initialImages.sort());
        }
    }, [initialImages]);

    useEffect(() => {
        if (initialImages) {
            const cacheImages = async () => {
                setLoading(true);
                const imageCache = {};

                const fetchImage = async (url) => {
                    try {
                        const response = await fetch(url);
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const blob = await response.blob();
                        const reader = new FileReader();

                        return new Promise((resolve, reject) => {
                            reader.onloadend = () => {
                                const image = new Image();
                                image.src = reader.result;
                                image.onload = () => {
                                    const width = image.width;
                                    const height = image.height;
                                    const name = url.split('/').pop().split('?')[0];

                                    resolve({
                                        base64: reader.result,
                                        name,
                                        url: url,
                                        size: `${width}x${height}`
                                    });
                                };
                                image.onerror = reject;
                            };
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        });
                    } catch (error) {
                        console.error('Error fetching image:', error);
                        return null;
                    }
                };

                for (const url of initialImages) {
                    const imageData = await fetchImage(url);
                    if (imageData) {
                        imageCache[url] = imageData;
                    }
                }
                setCachedImages(imageCache);
                setLoading(false);
            };

            if (needToDownload) {
                cacheImages();
            } else {
                setCachedImages(parameters.photos)
            }
        };
    }, [initialImages]);

    useEffect(() => {
        setBooks(createBooks(cnt));
    }, [cnt, droppedImages]);

    useEffect(() => {
        const hasDroppedImages = Object.keys(droppedImages).length > 0;
        setIsAutoInsertDisabled(hasDroppedImages);
    }, [droppedImages]);

    function isValidSize(imageSize, targetSize) {
        const [targetWidth, targetHeight] = targetSize.split('x').map(Number);
        const [imageWidth, imageHeight] = imageSize.split('x').map(Number);

        return (
            Math.abs(targetWidth - imageWidth) <= 2 &&
            Math.abs(targetHeight - imageHeight) <= 2
        );
    }

    function validateImageSize(id, imageSrc) {
        try {
            const imageSize = cachedImages[imageSrc].size;
            if (id.match(/\bPage_0\b/)) {
                if (!isValidSize(imageSize, coverSize)) {
                    setErrorModalVisible(true);
                    setError((prevErrors) => [...prevErrors, 'Неправильный размер изображения для обложки']);
                    return false;
                }
            } else {
                if (!isValidSize(imageSize, blockSize)) {
                    setErrorModalVisible(true);
                    setError((prevErrors) => [...prevErrors, 'Неправильный размер изображения для разворота']);
                    return false;
                }
            }

            return true;
        } catch (error) {
            console.error('Ошибка при валидации размера изображения:', error);
            return false;
        }
    }


    const handleDrop = (src, id) => {
        const isValid = validateImageSize(id, src);
        if (!isValid) return;

        setDroppedImages(prev => ({
            ...prev,
            [id]: src
        }));
        setAvailableImages(prevImages =>
            prevImages.filter(image => image !== src)
        );
    };

    const handleReplace = (src, id) => {
        let replacedImage;

        const isValid = validateImageSize(id, src);
        if (!isValid) return;

        setDroppedImages(prev => {
            const updated = { ...prev }
            replacedImage = updated[id]
            updated[id] = src
            return updated
        });
        setAvailableImages(prevImages => {
            return [...prevImages, replacedImage].sort();
        });
    };

    const handleAutoInsert = () => {
        if (!isAutoInsertDisabled && mode === 'autoButton') {
            const newDroppedImages = { ...droppedImages };
            const availableImagesCopy = [...availableImages];
            let error = false;

            Promise.all(books.map(book =>
                Promise.all(book.map(async (page) => {
                    if (!newDroppedImages[page.id] && availableImagesCopy.length > 0) {
                        const image = availableImagesCopy.shift();
                        const isValid = validateImageSize(page.id, image);
                        if (isValid) {
                            newDroppedImages[page.id] = image;
                        } else {
                            error = true;
                        }
                    }
                }))
            )).then(() => {
                if (!error) {
                    setDroppedImages(newDroppedImages);
                    setAvailableImages(availableImagesCopy);
                }
            });
        }
    };

    const handleClearAll = () => {
        setDroppedImages({});
        setAvailableImages(initialImages.sort());
    };

    const handleClearBook = (bookIndex) => {
        books[bookIndex].forEach(page => {
            handleDelete(page.id);
        });
    };

    const handleCopy = (id) => {
        if (mode === 'copyButtons') {
            const outerIndex = books.findIndex(subArray =>
                subArray.some(item => item.id === id)
            );
            if (outerIndex === -1) {
                alert('Произошла ошибка!')
                return;
            }
            const innerIndex = books[outerIndex].findIndex(item => item.id === id)

            if (books[outerIndex + 1] && droppedImages[books[outerIndex][innerIndex].id]) {
                const updatedDroppedImages = JSON.parse(JSON.stringify(droppedImages));

                updatedDroppedImages[books[outerIndex + 1][innerIndex].id] = updatedDroppedImages[books[outerIndex][innerIndex].id];
                setDroppedImages(updatedDroppedImages);
            }
        }
    };

    const handleCopyAll = (id) => {
        if (mode === 'copyButtons') {
            const outerIndex = books.findIndex(subArray =>
                subArray.some(item => item.id === id)
            );
            if (outerIndex === -1) {
                alert('Произошла ошибка!');
                return;
            }
            const innerIndex = books[outerIndex].findIndex(item => item.id === id);

            if (droppedImages[books[outerIndex][innerIndex].id]) {
                const updatedDroppedImages = { ...droppedImages };
                for (let i = 0; i < books.length; i++) {
                    setTimeout(() => {
                        updatedDroppedImages[books[i][innerIndex].id] = updatedDroppedImages[books[outerIndex][innerIndex].id];
                    }, 0);
                }

                setDroppedImages(updatedDroppedImages);
            }
        }
    };

    const handleDelete = (id) => {
        if (droppedImages[id]) {
            const imageToDelete = droppedImages[id];
            setDroppedImages(prev => {
                const updated = { ...prev };
                delete updated[id];

                const isImageStillUsed = Object.values(updated).some(image => image === imageToDelete);

                if (!isImageStillUsed) {
                    setAvailableImages(prevImages => {
                        return [...prevImages, imageToDelete].sort();
                    });
                }

                return updated;
            });
        }
    };

    const handleZoom = (id) => {
        if (droppedImages[id]) {
            setZoomedImage(cachedImages[droppedImages[id]].base64);
        }
    };

    const closeModal = () => {
        setZoomedImage(null);
    };

    const handleScreenshot = async () => {
        const innerCards = document.querySelectorAll('.innerCard');
        if (innerCards.length === 0) {
            console.error('Элементы с классом .innerCard не найдены.');
            return;
        }
        for (let i = 0; i < innerCards.length; i++) {
            const element = innerCards[i];

            const canvas = await html2canvas(element, {
                scale: 0.6,
                scrollX: 0,
                scrollY: -window.scrollY,
                windowWidth: element.scrollWidth,
                windowHeight: element.scrollHeight,
            });

            const imageData = canvas.toDataURL('image/jpeg');

            if (!imageData || imageData === 'data:,') {
                console.error(`Ошибка создания скриншота для элемента ${i + 1}.`);
                continue;
            }
            const downloadLink = document.createElement('a');
            downloadLink.href = imageData;
            downloadLink.download = `Книга_${i + 1}.jpg`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    const handleSumbit = () => {
        const isAllBooksValid = books[0].every(item => item.hasOwnProperty('currentImage') && item.currentImage);
        if (!isAllBooksValid) {
            setErrorModalVisible(true);
            setError((prevErrors) => [...prevErrors, 'Заполнены не все поля!']);
        }

        if (getCookie('authorization') && isAllBooksValid) {
            const patchData = {
                status: 1,
                photos: books,
            }
            fetch(`${process.env.REACT_APP_URL}/order/?uuid=${parameters.orderUuid}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${getCookie('authorization')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(patchData)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Ошибка запроса");
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data)

                    navigate('/lk');
                })
                .catch(error => {
                    console.error("Ошибка при обработке ответа:", error);
                });
        }
    }

    return (
        <>
            <OrderPhotoTape availableImages={availableImages} cachedImages={cachedImages} setZoomedImage={setZoomedImage} />

            <div className="orderHeader">
                <ul className="orderHeaderList">
                    <li
                        className={`${(isAutoInsertDisabled || mode !== 'autoButton') && 'disabledButton'} orderHeaderListName upload_all`}
                        onClick={handleAutoInsert}
                    >
                        Вставить автоматически
                    </li>
                    <li className="orderHeaderListName clear_all" onClick={handleClearAll}>Очистить все</li>
                    <li className="orderHeaderListName">Видео</li>
                    <li className="orderHeaderListIcon"><a href="#0"></a></li>
                </ul>
            </div>

            <div className="main">
                {books.map((el, ind) => (
                    <BookCard
                        cachedImages={cachedImages}
                        key={`book_${ind}`}
                        ind={ind}
                        mode={mode}
                        pages={el}
                        onClearBook={handleClearBook}
                        onCopy={handleCopy}
                        onCopyAll={handleCopyAll}
                        onDrop={handleDrop}
                        onReplace={handleReplace}
                        onDelete={handleDelete}
                        onZoom={handleZoom}
                        coverSize={coverSize}
                        blockSize={blockSize}
                    />
                ))}

                <div className="orderTotal">
                    <div className="orderTotalLeft">
                        <p>{`Количество книг: ${cnt.numberOfBooks}шт.`}</p>
                        <p>{`Масса: ${(parameters.parameters.weight / 1000).toFixed(2)}кг`}</p>
                        <p>{`Цена: ${parameters.parameters.price}р`}</p>
                    </div>
                    <div className="orderTotalRight">
                        <button className="orderTotalButtonCanel" onClick={() => { navigate('/') }}>Отмена</button>
                        <button className="orderTotalButtonConfirm" onClick={handleSumbit}>Оформить</button>
                    </div>
                </div>
            </div>

            {zoomedImage && <ZoomModal image={zoomedImage} closeModal={closeModal} />}
            {errorModalVisible && <ErrorModal errorMessage={error} onClose={() => { setErrorModalVisible(false); setError([]); }} />}
            {loading && <LoaderModal />}

        </>
    );
}
