import LkUserTable from '../../components/LkUserTable/LkUserTable'
import './lkPage.css'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import deleteCookie from '../../functions/deleteCookie'
import { useEffect, useState } from 'react'
import formatPrice from '../../functions/formatPrice'
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/userReducer'
import getCookie from '../../functions/getCookie'
import ErrorModal from '../../components/ErrorModal/ErrorModal'


export default function LkPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user)
    const [filters, setFilters] = useState('inWork');
    const [data, setData] = useState(null);
    const [orders, setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [error, setError] = useState([]);


    function getBonusStatusText(status) {
        switch (status) {
            case 'Base':
                return 'Стандартный'
            case 'Bronze':
                return 'Бронзовый'
            case 'Silver':
                return 'Серебряный'
            case 'Gold':
                return 'Золотой'
            default:
                return 'Стандартный'
        }
    }

    function getFilterText(text) {
        switch (text) {
            case 'inWork':
                return 'В работе'
            case 'inPrint':
                return 'В печати'
            case 'history':
                return 'История'
            default:
                return 'В работе'
        }
    }

    function handleClickLogout() {
        deleteCookie('authorization');
        dispatch(userActions.unsetUser())
        navigate('/')
    }

    useEffect(() => {
        if (getCookie('authorization')) {

            fetch(`${process.env.REACT_APP_URL}/order`, {
                headers: {
                    Authorization: `Bearer ${getCookie('authorization')}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Ошибка запроса");
                    }
                    return response.json();
                })
                .then(data => {
                    const filtredData = {
                        inWork: [],
                        inPrint: [],
                        history: [],
                    }

                    data.forEach((el) => {
                        if (el.status === 1 || el.status === 0) { //пока что игнорим, статус 0 (фотки загружены, но не расставлены)
                            filtredData.inWork.push(el);
                        }
                        if (el.status === 2) {
                            filtredData.inPrint.push(el);
                        }
                        if (el.status === 3) {
                            filtredData.history.push(el);
                        }
                    })
                    setData(filtredData);
                })
                .catch(error => {
                    console.error("Ошибка при обработке ответа:", error);
                });
        }
    }, [])

    useEffect(() => {
        if (data) {
            if (filters === 'inWork') {
                setOrders(data.inWork)
            }
            if (filters === 'inPrint') {
                setOrders(data.inPrint)
            }
            if (filters === 'history') {
                setOrders(data.history)
            }
        }
    }, [filters, data])

    const handleDeleteOrder = () => {
        if (selectedOrders.length === 0) {
            setError((prevErrors) => [...prevErrors, 'Вы не выбрали ни одного заказа!']);
            return;
        }
        selectedOrders.forEach((order) => {
            if (getCookie('authorization')) {

                fetch(`${process.env.REACT_APP_URL}/order/?uuid=${order}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${getCookie('authorization')}`
                    }
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error("Ошибка запроса");
                        }
                        return response.json();
                    })
                    .then(data => {
                        setData((prevData) => ({
                            ...prevData,
                            inWork: prevData.inWork.filter((el) => el.uuid !== order),
                        }));
                        setSelectedOrders([]);
                    })
                    .catch(error => {
                        console.error("Ошибка при обработке ответа:", error);
                    });
            }
        })
    }

    const handleLinkOrders = () => {
        let allValid = true;
        if (selectedOrders.length === 0) {
            allValid = false;
            setError((prevErrors) => [...prevErrors, 'Чтобы связать заказы их необходимо выделить галочкой.']);
            setError((prevErrors) => [...prevErrors, 'Связывание заказов необходимо, чтобы объединить в один срок печати и отправку одним (по возможности) отправлением одного класса, если в нём присутствуют книги разных форматов или на разное количество страниц. При связывании заказов оплата производится одной транзакцией, адрес отправки также можно указывать только для мастер-заказа» Мастер-заказом всегда будет считаться тот, который был сформирован раньше!']);
            return
        }
        if (selectedOrders.length === 1) {
            allValid = false;
            setError((prevErrors) => [...prevErrors, 'Выделите галочкой все заказы, которые хотите связать!']);
            return
        }

        const resultIndices = [];
        orders.forEach((order, index) => {
            if (selectedOrders.includes(order.uuid)) {
                if (order.status !== 1) {
                    allValid = false;
                    setError((prevErrors) => [...prevErrors, `Связывать можно только готовые к отправке в печать заказы. В заказе: "${order.name}" не размещены макеты!`]);
                    return
                }

                resultIndices.push(index);
            }
        });
        if (allValid) {
            for (let i = 1; i < resultIndices.length; i++) {
                if (resultIndices[i] - resultIndices[i - 1] !== 1) {
                    allValid = false;
                    setError((prevErrors) => [...prevErrors, 'Связывать можно только заказы идущие подряд (друг за другом)']);
                    return
                }
            }
        }

        const updatedData = { ...data, inWork: [...data.inWork] };


        const slavesArr = [...selectedOrders];
        const masterIndex = slavesArr.indexOf(updatedData.inWork[resultIndices[0]].uuid);
        if (masterIndex !== -1) {
            slavesArr.splice(masterIndex, 1);
        }

        resultIndices.forEach((el, ind) => {
            if (ind === 0) {


                updatedData.inWork[el] = {
                    ...updatedData.inWork[el],
                    isMaster: true,
                    slaves: [...slavesArr],
                };

                const patchData = {
                    isMaster: true,
                    slaves: [...slavesArr],
                }
                if (getCookie('authorization')) {
                    fetch(`${process.env.REACT_APP_URL}/order/?uuid=${updatedData.inWork[el].uuid}`, {
                        method: 'PATCH',
                        headers: {
                            Authorization: `Bearer ${getCookie('authorization')}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(patchData)
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error("Ошибка запроса");
                            }
                            return response.json();
                        })
                        .then(data => {
                        })
                        .catch(error => {
                            console.error("Ошибка при обработке ответа:", error);
                            return
                        });
                }
            } else {
                updatedData.inWork[el] = {
                    ...updatedData.inWork[el],
                    master: selectedOrders[masterIndex],
                    isSlave: true,
                };

                const patchData = {
                    master: selectedOrders[masterIndex],
                    isSlave: true,
                }

                if (getCookie('authorization')) {
                    fetch(`${process.env.REACT_APP_URL}/order/?uuid=${updatedData.inWork[el].uuid}`, {
                        method: 'PATCH',
                        headers: {
                            Authorization: `Bearer ${getCookie('authorization')}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(patchData)
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error("Ошибка запроса");
                            }
                            return response.json();
                        })
                        .then(data => {
                        })
                        .catch(error => {
                            console.error("Ошибка при обработке ответа:", error);
                            return
                        });
                }
            }
        });

        setData(updatedData);
    }

    const handleGoToConstruktor = () => {
        if (selectedOrders.length === 0) {
            setError((prevErrors) => [...prevErrors, 'Не выбран заказ!']);
            return
        }
        if (selectedOrders.length > 1) {
            setError((prevErrors) => [...prevErrors, 'Можно выбрать только 1 заказ!']);
            return
        }
        const selectedOrder = data.inWork.find(order => order.uuid === selectedOrders[0]);
        selectedOrder.parameters.status = selectedOrder.status;
        selectedOrder.parameters.weight = selectedOrder.weight * 1000;
        selectedOrder.parameters.price = selectedOrder.price;

        navigate('/construktor', { state: selectedOrder })
    }

    return (
        <>
            <main style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="lkTopContent">
                    <div className="lkTop">
                        <div className="lkTopLeft">
                            <div className="lkTopButtons">
                                <button className="lkButton" onClick={() => { navigate('/products') }}>Создать заказ</button>
                                <button className="lkButton" onClick={() => { setFilters('inWork') }}>В работе</button>
                                <button className="lkButton" onClick={() => { setFilters('inPrint') }}>В печати</button>
                                <button className="lkButton" onClick={() => { setFilters('history') }}>История</button>
                            </div>
                        </div>
                        <div className="lkTopRight">
                            <div className="lkTopLogOut">
                                <button onClick={handleClickLogout}>Выход</button>
                            </div>
                            <div className="lkTopUser">
                                <h2>{`${user.name} ${user.surname}`}</h2>
                                <div className={`lkTopUserMedal lkTopUserMedalIcon_${user.bonusStatus}`}></div>
                                <div className="lkTopUserText">
                                    <p>Бонусный рейтинг: <span className={`lkTopUserStatus_${user.bonusStatus}`}>{getBonusStatusText(user.bonusStatus)}</span></p>
                                    <p>Бонусный счет: {formatPrice(user.bonus)} р.</p>
                                    <p>При пополнении баланса, за каждые внесённые 25&#160;000 рублей Вам будет начислятся 1% бонусов</p>
                                </div>
                                <button className="lkButtonEditUserInfo" style={{ width: '60%' }} onClick={() => { navigate('/lk/edit') }}>Редактировать личные данные</button>

                            </div>
                            <div className="lkTopAccount">
                                <p>Лицевой счет: <span className="lkTopAccountMoney">{formatPrice(user.money)}</span> р.</p>
                                <button className="lkButtonDeposit">Пополнить</button>
                            </div>

                        </div>
                    </div>
                </div>
                <h3 style={{ width: '100%', textAlign: "center", marginTop: '25px', marginBottom: '0' }}>{getFilterText(filters)}</h3>
                <LkUserTable filters={filters} orders={orders} selectedOrders={selectedOrders} setSelectedOrders={setSelectedOrders} />
                <div className="lkFooter">
                    {filters !== 'inWork' ? <></> : (
                        <div className="lkFooterButtons">
                            <button className="lkButton" onClick={handleLinkOrders}>Связать</button>
                            <button className="lkButton">Отправить в печать</button>
                            <button className="lkButton" onClick={handleGoToConstruktor}>Перейти в конструктор</button>
                            <button className="lkButton" onClick={handleDeleteOrder}>Удалить</button>
                        </div>
                    )}
                </div>
            </main>

            {error.length > 0 && <ErrorModal errorMessage={error} onClose={() => { setError([]); }} />}
        </>
    )
}